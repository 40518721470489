import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { SocialIcon } from "react-social-icons/component";
import "react-social-icons/whatsapp";
import "react-social-icons/facebook";
import { findSelf, findWho } from "../../utils/functions";
import { t } from "i18next";
import { useLazyGetInviteStringQuery } from "../../redux/slice/inviteString";
import { Controller, useForm } from "react-hook-form";
import { regularExpressions } from "../../utils/constants";
import LoadingButton from "@mui/lab/LoadingButton";

export const InvitePeople = ({ close = () => {}, relation, lg_id }) => {
  //-----------------------Declare Hooks Here-----------------------------//
  const defaultValues = {
    emailid: "",
  };
  const [getInviteString, { data, isLoading }] = useLazyGetInviteStringQuery();
  const [open, setOpen] = useState(false);
  const self = findSelf();
  const { handleSubmit, control, formState, getValues } = useForm({
    mode: "onChange",
    defaultValues,
  });
  let link = data
    ? `${window.location.hostname}/signup?token=${encodeURIComponent(
        data.inviteString
      )}&email=${getValues("emailid")}`
    : "";
  let description = `${self.fname} ${
    self.lname
  } is inviting you to connect with ${
    findWho(lg_id)?.name
  } on famscape. click on the below link to connect and explore your family`;
  let encodedlink = encodeURIComponent(link);
  let encodedDescription = encodeURIComponent(description);
  const inviteMessage = `${encodedDescription} \n${encodedlink}`;

  const vertical = "bottom";
  const horizontal = "center";
  const handleGetInviteString = (data) => {
    getInviteString({
      proposedRelation: relation,
      baseLgId: lg_id,
      inviteeEmail: data.emailid,
    });
  };
  return (
    <Stack spacing={1}>
      <Controller
        name={"emailid"}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            required={true}
            label={t("inputField.email")}
            type={"email"}
            variant={"outlined"}
            error={fieldState.error ? true : false}
            helperText={fieldState.error ? fieldState.error.message : null}
            sx={{ width: "100%" }}
          />
        )}
        rules={{
          required: {
            value: true,
            message: t("error.fieldIsRequired", {
              param1: t("inputField.email"),
            }),
          },
          pattern: {
            value: regularExpressions.email,
            message: t("error.emailValidationErr"),
          },
        }}
      />
      <LoadingButton
        onClick={handleSubmit((data) => handleGetInviteString(data))}
        variant="contained"
        disabled={!formState.isValid}
        loading={isLoading}
      >
        {t(`button.invite`)}
      </LoadingButton>
      {data?.inviteString && (
        <Stack
          sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          direction={"row"}
          spacing={5}
        >
          <SocialIcon
            network="whatsapp"
            url={`https://wa.me/?text=${inviteMessage}`}
            target="_blank"
            onClick={close}
          />
          <SocialIcon
            network="facebook"
            url={`https://www.facebook.com/sharer/sharer.php?u=${encodedlink};src=sdkpreparse`}
            target="_blank"
            onClick={close}
          />
          <Button
            sx={{ p: 0, color: "grey" }}
            onClick={() => {
              navigator.clipboard.writeText(link);
              setOpen(true);
              // close();
            }}
          >
            <ContentCopyIcon sx={{ fontSize: "36px" }} />
          </Button>
          <Snackbar
            sx={{
              "& .MuiSnackbarContent-root": {
                minWidth: "0px",
              },
            }}
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={() => setOpen(false)}
            message={t("text.linkCopied")}
            key={vertical + horizontal}
            autoHideDuration={600}
          />
        </Stack>
      )}
    </Stack>
  );
};
