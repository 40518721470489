import { grey } from "@mui/material/colors";
import createTheme from "@mui/material/styles/createTheme";
import { makeStyles } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { t } from "i18next";

export const theme = createTheme({
  shadows: Array(25).fill("none"),
  palette: {
    mode: "light",
    primary: {
      main: "#4a154b",
      old: "#1976d2",
    },
    secondary: {
      main: "#d65db1",
    },
    white: {
      main: "#ffffff",
    },
    grey: { text: grey[500] },
    text: {
      redBg: "#515151",
      heading: "#333333",
      body: "#666666",
      grey: "#4B5563",
    },
    black: { main: "#000000" },
    border: { main: "#E5EAF2", yellow: "#FFC107" },
    background: {
      main: "#f2f2f2",
      dark: "#f7f9f9",
      comment: "#F1F3F4",
      selected: "rgba(0,0,0,0.2)",
      yellow: "#FFF8E1",
      button: "rgba(25, 118, 210, 0.04)",
      progressBar: "#4148A9",
    },
    button: { light: "rgba(25, 118, 210, 0.04)" },
    info: { main: "#009FE3" },
  },
  typography: {
    fontFamily: "Lato",
    h5: {
      fontSize: 24,
      fontWeight: 500,
    },
    fontSize: 14,
    subtitle1: {
      fontSize: 13,
    },
    button: {
      textTransform: "capitalize",
      textOverflow: "ellipsis",
    },
    caption: { color: grey[500] },
  },
  props: {
    MuiButton: {
      size: "small",
    },
    MuiButtonGroup: {
      size: "small",
    },
    MuiCheckbox: {
      size: "small",
    },
    MuiFab: {
      size: "small",
    },
    MuiFormControl: {
      margin: "dense",
      size: "small",
    },
    MuiFormHelperText: {
      margin: "dense",
    },
    MuiIconButton: {
      size: "small",
    },
    MuiInputBase: {
      margin: "dense",
    },
    MuiInputLabel: {
      margin: "dense",
    },
    MuiRadio: {
      size: "small",
    },
    MuiSwitch: {
      size: "small",
    },
    MuiTextField: {
      margin: "dense",
      size: "small",
    },
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiTable: {
      size: "small",
    },
    MuiTooltip: {
      arrow: true,
    },
    MuiAppBar: {
      color: "inherit",
    },
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 46,
          height: 27,
          padding: 0,
          margin: 8,
        },
        switchBase: {
          padding: 1,
          "&$checked, &$colorPrimary$checked, &$colorSecondary$checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + $track": {
              opacity: 1,
              border: "none",
            },
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 13,
          border: "1px solid #bdbdbd",
          backgroundColor: "#fafafa",
          opacity: 1,
          transition:
            "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.MuiButton-containedPrimary": {
            "&:hover": { backgroundColor: "#F1A32F" },
            backgroundColor: "#FFC43A",
            color: "#222222",
          },
          "&.MuiButton-containedSecondary": {
            // "&:hover": { backgroundColor: "#F1A32F" },
            backgroundColor: "#ACACB2",
            color: "#222222",
          },
          // "&:hover": { backgroundColor: "#F1A32F" },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderWidth: "1px",
            },
          },
        },
      },
    },
    //MuiOutlinedInput-notchedOutline
  },
  spacing: 8,
  shape: {
    borderRadius: 8,
  },
  overrides: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: "#fff",
        color: "#fff",
      },
    },
  },
});

export const useStyles = makeStyles((theme) => ({
  root: {
    "&::-webkit-scrollbar": {
      width: 1,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 8,
    },
  },
  profileBadge: {
    "&::-webkit-scrollbar": {
      width: 0,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent",
      borderRadius: 8,
    },
  },
  textFieldRoot: {
    "& > div.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      "&> span.MuiIconButton-root": {
        position: "absolute",
        right: "10px",
      },
      "& > div.MuiAutocomplete-endAdornment": {
        position: "absolute",
        right: "35px",
      },
    },
    "&>div.MuiInputBase-root": {
      "&> div.helperIcon": { position: "absolute", right: "10px" },
      "& svg": { right: "34px" },
    },
  },
}));

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& ul": { display: "flex", flexDirection: "column" },
  "& .MuiPaper-root": {
    borderRadius: 8,
    marginTop: theme.spacing(1),
    // minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "0px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const createRelationDescription = (param) => {
  const headingStyles = {
    fontSize: "16px",
    fontWeight: 600,
    color: "info.main",
  };
  const bodyStyles = { color: "text.grey" };
  return {
    father: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.parentHeading", { param: param })}
        </Typography>
        {/* <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.parentP1")}
            </Typography>
          </li>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.parentP2")}
            </Typography>
          </li>
        </ul> */}
      </Stack>
    ),
    mother: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.parentHeading", { param: param })}
        </Typography>
        {/* <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.parentP1")}
            </Typography>
          </li>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.parentP2")}
            </Typography>
          </li>
        </ul> */}
      </Stack>
    ),
    grandFather: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.grandParentsHeading", {
            param: param,
          })}
        </Typography>
        <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.grandParentsP1")}
            </Typography>
          </li>
          {/* <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.grandParentsP2")}
            </Typography>
          </li> */}
        </ul>
        <Typography sx={bodyStyles}>
          {" "}
          {t("createRelation.grandParentsNote")}
        </Typography>
      </Stack>
    ),
    greatGrandFather: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.grandParentsHeading", {
            param: param,
          })}
        </Typography>
        <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.greatGrandParentsP1")}
            </Typography>
          </li>
          {/* <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.grandParentsP2")}
            </Typography>
          </li> */}
        </ul>
        <Typography sx={bodyStyles}>
          {" "}
          {t("createRelation.grandParentsNote")}
        </Typography>
      </Stack>
    ),
    greatGrandMother: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.grandParentsHeading", {
            param: param,
          })}
        </Typography>
        <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.greatGrandParentsP1")}
            </Typography>
          </li>
          {/* <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.grandParentsP2")}
            </Typography>
          </li> */}
        </ul>
        <Typography sx={bodyStyles}>
          {" "}
          {t("createRelation.grandParentsNote")}
        </Typography>
      </Stack>
    ),
    grandMother: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.grandParentsHeading", {
            param: param,
          })}
        </Typography>
        <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.grandParentsP1")}
            </Typography>
          </li>
          {/* <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.grandParentsP2")}
            </Typography>
          </li> */}
        </ul>
        <Typography sx={bodyStyles}>
          {" "}
          {t("createRelation.grandParentsNote")}
        </Typography>
      </Stack>
    ),
    brother: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.siblingsHeading", {
            param: param,
          })}
        </Typography>
        {/* <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.siblingsP1")}
            </Typography>
          </li>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.siblingsP2")}
            </Typography>
          </li>
        </ul> */}
        <Typography sx={bodyStyles}>
          {" "}
          {t("createRelation.siblingsNote")}
        </Typography>
      </Stack>
    ),
    sister: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.siblingsHeading", {
            param: param,
          })}
        </Typography>
        {/* <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.siblingsP1")}
            </Typography>
          </li>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.siblingsP2")}
            </Typography>
          </li>
        </ul> */}
        <Typography sx={bodyStyles}>
          {" "}
          {t("createRelation.siblingsNote")}
        </Typography>
      </Stack>
    ),
    uncle: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.uncleAuntHeading", {
            param: param,
          })}
        </Typography>
        {/* <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.uncleAuntP1")}
            </Typography>
          </li>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.uncleAuntP2")}
            </Typography>
          </li>
        </ul> */}
        <Typography sx={bodyStyles}>
          {" "}
          {t("createRelation.uncleAuntNote")}
        </Typography>
      </Stack>
    ),
    aunt: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.uncleAuntHeading", {
            param: param,
          })}
        </Typography>
        {/* <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.uncleAuntP1")}
            </Typography>
          </li>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.uncleAuntP2")}
            </Typography>
          </li>
        </ul> */}
        <Typography sx={bodyStyles}>
          {" "}
          {t("createRelation.uncleAuntNote")}
        </Typography>
      </Stack>
    ),
    husband: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.spouseHeading", { param: param })}
        </Typography>
        {/* <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.spouseP1")}
            </Typography>
          </li>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.spouseP2")}
            </Typography>
          </li>
        </ul> */}
      </Stack>
    ),
    wife: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.spouseHeading", { param: param })}
        </Typography>
        {/* <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.spouseP1")}
            </Typography>
          </li>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.spouseP2")}
            </Typography>
          </li>
        </ul> */}
      </Stack>
    ),
    son: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.childrenHeading", {
            param: param,
          })}
        </Typography>
        {/* <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.childrenP1")}
            </Typography>
          </li>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.childrenP2")}
            </Typography>
          </li>
        </ul> */}
        <Typography sx={bodyStyles}>
          {" "}
          {t("createRelation.childrenNote")}
        </Typography>
      </Stack>
    ),
    daughter: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.childrenHeading", {
            param: param,
          })}
        </Typography>
        {/* <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.childrenP1")}
            </Typography>
          </li>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.childrenP2")}
            </Typography>
          </li>
        </ul> */}
        <Typography sx={bodyStyles}>
          {" "}
          {t("createRelation.childrenNote")}
        </Typography>
      </Stack>
    ),
    others: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>{t("createRelation.others")}</Typography>
        <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.othersP1")}
            </Typography>
          </li>
        </ul>
      </Stack>
    ),
    grandSon: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.grandChildrenHeading", {
            param: param,
          })}
        </Typography>
        <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.grandChildrenP1")}
            </Typography>
          </li>
          {/* <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.grandChildrenP2")}
            </Typography>
          </li> */}
        </ul>
        <Typography sx={bodyStyles}>
          {" "}
          {t("createRelation.grandChildrenNote")}
        </Typography>
      </Stack>
    ),
    grandDaughter: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.grandChildrenHeading", {
            param: param,
          })}
        </Typography>
        <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.grandChildrenP1")}
            </Typography>
          </li>
          {/* <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.grandChildrenP2")}
            </Typography>
          </li> */}
        </ul>
        <Typography sx={bodyStyles}>
          {" "}
          {t("createRelation.grandChildrenNote")}
        </Typography>
      </Stack>
    ),
    cousinBrother: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.cousinHeading")}
        </Typography>
        <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.cousinP1")}
            </Typography>
          </li>
          {/* <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.cousinP2")}
            </Typography>
          </li> */}
          {/* <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.cousinP3")}
            </Typography>
          </li>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.cousinP4")}
            </Typography>
          </li> */}
        </ul>
        <Typography sx={bodyStyles}>
          {" "}
          {t("createRelation.cousinNote")}
        </Typography>
      </Stack>
    ),
    cousinSister: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.cousinHeading")}
        </Typography>
        <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.cousinP1")}
            </Typography>
          </li>
          {/* <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.cousinP2")}
            </Typography>
          </li> */}
          {/* <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.cousinP3")}
            </Typography>
          </li>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.cousinP4")}
            </Typography>
          </li> */}
        </ul>
        <Typography sx={bodyStyles}>
          {" "}
          {t("createRelation.cousinNote")}
        </Typography>
      </Stack>
    ),
    fatherInlaw: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.inlawsHeading", {
            param: param,
          })}
        </Typography>
        {/* <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.inlawsP1")}
            </Typography>
          </li>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.inlawsP2")}
            </Typography>
          </li>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.inlawsP3")}
            </Typography>
          </li>
        </ul> */}
        <Typography sx={bodyStyles}>
          {" "}
          {t("createRelation.inlawsNote")}
        </Typography>
      </Stack>
    ),
    motherInlaw: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.inlawsHeading", {
            param: param,
          })}
        </Typography>
        {/* <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.inlawsP1")}
            </Typography>
          </li>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.inlawsP2")}
            </Typography>
          </li>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.inlawsP3")}
            </Typography>
          </li>
        </ul> */}
        <Typography sx={bodyStyles}>
          {" "}
          {t("createRelation.inlawsNote")}
        </Typography>
      </Stack>
    ),
    sisterInlaw: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.inlawsHeading", {
            param: param,
          })}
        </Typography>
        {/* <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.inlawsP1")}
            </Typography>
          </li>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.inlawsP2")}
            </Typography>
          </li>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.inlawsP3")}
            </Typography>
          </li>
        </ul> */}
        <Typography sx={bodyStyles}>
          {" "}
          {t("createRelation.inlawsNote")}
        </Typography>
      </Stack>
    ),
    brotherInlaw: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.inlawsHeading", {
            param: param,
          })}
        </Typography>
        {/* <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.inlawsP1")}
            </Typography>
          </li>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.inlawsP2")}
            </Typography>
          </li>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.inlawsP3")}
            </Typography>
          </li>
        </ul> */}
        <Typography sx={bodyStyles}>
          {" "}
          {t("createRelation.inlawsNote")}
        </Typography>
      </Stack>
    ),
    niece: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.nieceNephewHeading")}
        </Typography>
        <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.nieceNephew")}
            </Typography>
          </li>
        </ul>
      </Stack>
    ),
    nephew: (
      <Stack spacing={1}>
        <Typography sx={headingStyles}>
          {t("createRelation.nieceNephewHeading")}
        </Typography>
        <ul>
          <li>
            <Typography sx={bodyStyles}>
              {t("createRelation.nieceNephew")}
            </Typography>
          </li>
        </ul>
      </Stack>
    ),
  };
};
