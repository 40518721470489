import React from "react";
import { CustomDialog } from "../../UI/CustomDialog";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { findEventType } from "../../utils/functions";

export const ClaimLgPromt = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const { claimedLgOwner } = useSelector((state) => state.userSummary);
  const eventType = findEventType("info");

  return (
    <>
      <CustomDialog
        title={
          <Typography
            variant="h6"
            sx={{ fontSize: "22px", fontWeight: 500 }}
            align="center"
          >
            {t("text.claimLgTitle")}
          </Typography>
        }
        open={true}
        color={eventType?.color}
        fontColor={eventType?.fontColor}
        icon={eventType?.icon}
        iconPlacement="left"
      >
        <Box
          sx={{
            px: 2,
            pb: 2,
          }}
          className="derek"
        >
          {/* <Typography color="#a020f0" align="center" variant="h4">
            {t("text.introHeading")}
          </Typography> */}
          <Typography
            align="justify"
            sx={{ textIndent: "30px", mt: 2 }}
            paragraph
            variant="body2"
          >
            {t("text.claimLgP1")}
          </Typography>
          {claimedLgOwner.map((item) => (
            <ul>
              <li>
                <Typography
                  align="justify"
                  sx={{ fontWeight: "bold" }}
                  paragraph
                  variant="body2"
                >
                  {item.owner_name}
                </Typography>
              </li>
            </ul>
          ))}
        </Box>
      </CustomDialog>
    </>
  );
};
