import { setAlert } from "../redux/slice/alert";
import {
  updateNotificationPromt,
  updatePreferedLanguage,
} from "../redux/slice/authentication";
import {
  updateInviteToken,
  updateAppReady,
  updateUserStatus,
} from "../redux/slice/configuration";
import { updateCurrentPage, updateMode } from "../redux/slice/createLg";
import { updateActiveNodeLgId } from "../redux/slice/familyTree";
import {
  updateNudgePost,
  updateRelReqNotification,
} from "../redux/slice/notification";
import {
  updateBlockedLg,
  updateClaimedLgOwner,
  updateOwnedLg,
  updatePendingRelation,
  updateTrending,
  updateUserPlan,
} from "../redux/slice/userSummary";
import { store } from "../redux/store";
import { findSelf, getApi } from "../utils/functions";
import { getRelationsApi } from "./getRelationsApi";

export const getUserSummaryApi = () => {
  return getApi("getUsrSummary").then(async (response) => {
    if (!response.error) {
      if (response.ownedLG.length > 1) {
        store.dispatch(updateNotificationPromt({ eligible: true }));
      }
      store.dispatch(updateInviteToken(response.inviteString));
      store.dispatch(
        updateUserStatus(
          response.user_status === "passive" ? "inactive" : "active"
        )
      );
      store.dispatch(updateBlockedLg(response.blocking_lg_list));
      store.dispatch(updateOwnedLg(response.ownedLG));
      store.dispatch(updateRelReqNotification(response?.pending_rel_req));
      store.dispatch(updateUserPlan(response.userAcct));
      store.dispatch(updatePreferedLanguage(response.userAcct.pref_lang));
      store.dispatch(updateTrending(response.trending));
      store.dispatch(
        updatePendingRelation(response.pendingRelRequests.relReqList)
      );
      let self = findSelf();
      store.dispatch(
        updateActiveNodeLgId({
          lg_id: self?.lg_id,
          name: `${self?.fname} ${self?.lname}`,
        })
      );
      store.dispatch(
        updateNudgePost({
          nudgeAddRelations: self?.nudgeAddRelations,
          nudgeNewPost: self?.nudgeNewPost,
          nudgeProfileDetails: self?.nudgeProfileDetails,
        })
      );
      if (response.ownedLG.length === 0) {
        if (response.user_claimed_lg && response.user_status === "active") {
          store.dispatch(updateAppReady("claimLg"));
          store.dispatch(updateClaimedLgOwner(response.user_claimed_lg_owners));
        } else {
          store.dispatch(updateMode("self"));
          store.dispatch(updateCurrentPage("welcome"));
          store.dispatch(updateAppReady("self"));
        }
      } else {
        let self = findSelf();
        if (self) {
          await getRelationsApi(self?.lg_id);
          return;
        }
        store.dispatch(updateAppReady(true));
      }
    } else {
      store.dispatch(setAlert(response));
    }
  });
};
