import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import CustomAvatar from "../../UI/CustomAvathar";
import { findOwner, formatDate } from "../../utils/functions";
import { t } from "i18next";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import { DeleteEntryPromt } from "../atoms/DeleteEntryPromt";
import { useWithdrawRelReqMutation } from "../../redux/slice/withdrawRelReq";

export const RelationRequestSentCard = ({
  base_relation,
  decider_name,
  decider_url,
  decision_date,
  fstr,
  lg_id,
  lg_id_name,
  lg_id_url,
  objKey,
  readURL,
  related_lg_id,
  req_decision,
  request_direction = "",
  requested_date,
  requestor_name,
  requestor_url,
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const owner = findOwner(related_lg_id);
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [withdrawRelReq, { isLoading }] = useWithdrawRelReqMutation();
  const handleClose = () => {
    setSelectedItem(undefined);
  };
  const handleConfirm = () => {
    withdrawRelReq(selectedItem);
  };
  const handleSelection = () => {
    setSelectedItem({
      lg_id: related_lg_id,
      base_lg_id: lg_id,
      base_relation: base_relation,
      req_decision: "Withdrawn",
    });
  };
  return (
    <>
      <Card
        sx={{
          borderStyle: "solid",
          borderColor: "border.main",
          borderWidth: "thin",
          p: 0.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            cursor: "pointer",
            gap: 0.5,
          }}
        >
          <Typography variant="caption">
            {t("text.requestedBy", {
              param1: requestor_name,
              param2: formatDate(requested_date),
            })}{" "}
          </Typography>
          {/* <Typography variant="caption">{requestor_name}</Typography> */}
        </Box>
        <Divider></Divider>
        <Box
          //   direction={"row"}
          //   spacing={1}
          sx={{
            display: "inline-flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 0.5,
            width: "fit-content",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              display: "inline-flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            component={"div"}
            onClick={() =>
              navigate(`/profile/${lg_id_url.split("/")[4]}/about`)
            }
          >
            <Box sx={{ pt: 0.5 }}>
              <CustomAvatar
                sx={{ height: "20px", width: "20px" }}
                src={readURL}
              />
            </Box>
            {lg_id_name}
          </Typography>
          <Typography>
            {" "}
            {request_direction.toLowerCase() === "recieved"
              ? owner.owner_type === "Self"
                ? `${t("text.areThe")} ${base_relation} ${t("text.of")}`
                : `${t("text.isThe")} ${base_relation} ${t("text.of")}`
              : `${t("text.isThe")} ${base_relation} ${t("text.of")}`}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              display: "inline-flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            component={"div"}
            onClick={() => navigate(`/profile/${owner?.lg_url}/about`)}
          >
            <Box sx={{ pt: 0.5 }}>
              <CustomAvatar
                sx={{ height: "20px", width: "20px" }}
                src={owner?.readURL}
              />
            </Box>
            <Box>{`${owner.fname} ${owner.lname}`}</Box>
          </Typography>
        </Box>
        <Box>
          {req_decision === "Approved" && (
            <Typography variant="caption" sx={{ color: "green" }}>
              {t("text.approvedBy", {
                param1: decider_name,
                param2: formatDate(decision_date),
              })}
            </Typography>
          )}
          {req_decision === "Rejected" && (
            <Typography variant="caption" sx={{ color: "red" }}>
              {t("text.rejectedBy", {
                param1: decider_name,
                param2: formatDate(decision_date),
              })}
            </Typography>
          )}
          {req_decision === "Requested" && (
            <Stack
              direction={"row"}
              spacing={1}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {" "}
              <Typography variant="caption" sx={{ color: "orange" }}>
                {t("text.pending")} {decider_name}
              </Typography>{" "}
              <LoadingButton
                sx={{ p: 0, px: 0.7 }}
                variant="contained"
                onClick={handleSelection}
                loading={isLoading}
              >
                {t("button.cancelRequest")}
              </LoadingButton>
            </Stack>
          )}
        </Box>
        <DeleteEntryPromt
          open={Boolean(selectedItem)}
          close={handleClose}
          onCancel={handleClose}
          onDelete={handleConfirm}
          title={t("text.areYouSure")}
          message={
            <Typography
              sx={{
                wordWrap: "break-word",
                whiteSpace: "pre-wrap",
              }}
            >
              {t("text.withdrawRelationRequest")}
            </Typography>
          }
          type={"error"}
        />
      </Card>
    </>
  );
};
