import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import CustomAvatar from "./CustomAvathar";
import { useNavigate } from "react-router-dom";

export const CustomNameTag = ({
  src,
  name,
  link = "",
  url = "",
  secondaryText = "",
  selected = false,
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const temp = link ? link.split("/")[4] : url ? url : false;
  const navigate = useNavigate();

  //-----------------------Declare Functions Here-----------------------------//

  const handleClick = () => {
    if (temp) navigate(`/profile/${temp}/about`);
  };
  return (
    <MenuItem
      onClick={handleClick}
      disableGutters={true}
      disableRipple={true}
      disableTouchRipple={true}
      sx={{
        width: "100%",
        borderRadius: 1,
        backgroundColor: selected ? "background.selected" : null,
      }}
    >
      <Stack
        spacing={1}
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >
        <CustomAvatar src={src} />
        <Box sx={{ width: "70%" }}>
          <Typography
            sx={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textWrap: "nowrap",
              display: "block",
            }}
          >
            {name}
          </Typography>
          {secondaryText && (
            <Typography
              variant="caption"
              align="left"
              sx={{
                display: "block",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textWrap: "nowrap",
              }}
            >
              {secondaryText}
            </Typography>
          )}
        </Box>
      </Stack>
    </MenuItem>
  );
};
