import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomAvatar from "../../UI/CustomAvathar";
import { useSelector } from "react-redux";
import { getDirectRelation } from "../../api/getDirectRelation";
import { t } from "i18next";
import {
  chooseGenderFromValue,
  createNewLifografConfig,
  findSelf,
  findWho,
} from "../../utils/functions";
import { InvitePeople } from "../atoms/InvitePeople";
import { CustomDialogSwipeUp } from "../../UI/CustomDialogSwipeUp";
import { createRelationDescription, useStyles } from "../../utils/Theme";
import { DeleteEntryPromt } from "../atoms/DeleteEntryPromt";
import { relationshipIcons } from "../../utils/ListItems";
import { useContainerDimensions } from "../../utils/hooks";
import { CustomNameTag } from "../../UI/CustomNameTag";

export const ChooseRelationship = ({
  relatedTo,
  relationship,
  close = () => {},
  setRelationship = () => {},
  setRelatedTo = () => {},
  setStatus = () => {},
  disable = "",
  name = "",
  type = "relation",
  open,
  mode = "create",
  gender,
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const navigate = useNavigate();
  const { relations, ownedLg } = useSelector((state) => state.userSummary);
  const [uniqueRelation, setUniqueRelation] = useState(false);
  const [relationExist, setRelationExist] = useState(false);
  const [relationListLevel_2, setRelationListLevel_2] = useState([]);
  const [selectionLevel_2, setSelectionLevel_2] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    type === "spouse" ? "level_1" : "level_0"
  );
  const [selectionLevel_0, setSelectionLevel_0] = useState(
    type === "spouse" ? "spouse" : undefined
  );
  const createNewRelations = createNewLifografConfig(gender);
  const description = createRelationDescription(
    mode === "create"
      ? t("text.createYour").toLowerCase()
      : t("text.addYour").toLowerCase()
  );
  const ref = useRef();
  const { width } = useContainerDimensions(ref);
  const classes = useStyles();
  const self = findSelf();

  //-----------------------Declare Functions Here-----------------------------//
  const handleApi = async (lgId) => {
    let lg_id = lgId ? lgId : relatedTo?.userId;
    let isCreationPossible = ownedLg.find((item) => item.lg_id === lg_id);
    if (isCreationPossible) {
      setLoading(true);
      let response = await getDirectRelation(lg_id);
      setLoading(false);
      if (!response.error) {
        let isRelationExist = response.directRelationList.filter(
          (item) => item.relation === relationship
        );
        if (isRelationExist.length > 0) {
          if (relationship === "Father" || relationship === "Mother") {
            setCurrentPage("uniqueRelation");
            setUniqueRelation(isRelationExist);
            setStatus("alreadyExist");
          } else {
            setCurrentPage("relationExist");
            setRelationExist(isRelationExist);
            setStatus("areYouSure");
          }
        } else {
          close("next");
        }
      } else {
        setCurrentPage("notAColloboratorOrOwner");
      }
    }
  };
  const handleSelectionLevel_0 = (item) => {
    setRelationship(createNewRelations[item]?.relationship);
    setSelectionLevel_0(item);
  };

  const handleLevel_0 = () => {
    if (createNewRelations[selectionLevel_0]?.level === 0) {
      setRelatedTo(findWho(self.lg_id));
      handleApi(self?.lg_id);
    } else if (selectionLevel_0 === "others")
      setCurrentPage("noRelationPossible");
    else if (
      (selectionLevel_0 === "niece" || selectionLevel_0 === "nephew") &&
      mode === "create"
    ) {
      let temp = relations.find(
        (item) =>
          (item.relation.toLowerCase() === "brother" ||
            item.relation.toLowerCase() === "sister") &&
          item.relation_source.toLowerCase() === "direct"
      );
      if (!temp) {
        setCurrentPage("nieceNephew");
      } else {
        setCurrentPage("level_1");
      }
    } else if (
      (selectionLevel_0 === "grandFather" ||
        selectionLevel_0 === "grandMother") &&
      mode === "create"
    ) {
      let temp = relations.find(
        (item) =>
          item.relation.toLowerCase() === "mother" ||
          item.relation.toLowerCase() === "father" ||
          item.relation.toLowerCase().includes("parent")
      );
      if (!temp) {
        setCurrentPage("noPossibleGrandParents");
      } else {
        setCurrentPage("level_1");
      }
    } else if (
      (selectionLevel_0 === "greatGrandFather" ||
        selectionLevel_0 === "greatGrandMother") &&
      mode === "create"
    ) {
      let temp = relations.find(
        (item) =>
          item.relation.toLowerCase() === "mother" ||
          item.relation.toLowerCase() === "father" ||
          item.relation.toLowerCase().includes("parent")
      );
      if (!temp) {
        setCurrentPage("noPossibleGreatGrandParents");
      } else {
        setCurrentPage("level_1");
      }
    } else if (
      (selectionLevel_0 === "aunt" || selectionLevel_0 === "uncle") &&
      mode === "create"
    ) {
      let temp = relations.find(
        (item) =>
          item.relation.toLowerCase() === "mother" ||
          item.relation.toLowerCase() === "father" ||
          item.relation.toLowerCase().includes("parent")
      );
      if (!temp) {
        setCurrentPage("noPossibleUncleAunt");
      } else {
        setCurrentPage("level_1");
      }
    } else if (
      (selectionLevel_0 === "grandSon" ||
        selectionLevel_0 === "grandDaughter") &&
      mode === "create"
    ) {
      let temp = relations.find(
        (item) =>
          item.relation.toLowerCase() === "son" ||
          item.relation.toLowerCase() === "daughter" ||
          item.relation.toLowerCase().includes("children")
      );
      if (!temp) {
        setCurrentPage("noPossibleGrandChildren");
      } else {
        setCurrentPage("level_1");
      }
    } else if (
      (selectionLevel_0 === "cousinBrother" ||
        selectionLevel_0 === "cousinSister") &&
      mode === "create"
    ) {
      let temp = relations.find(
        (item) =>
          (item.relation.toLowerCase() === "uncle" ||
            item.relation.toLowerCase() === "aunt") &&
          item.relation_source.toLowerCase() === "direct"
      );
      if (!temp) {
        setCurrentPage("noCousinPossible");
      } else {
        setCurrentPage("level_1");
      }
    } else if (
      (selectionLevel_0 === "fatherInlaw" ||
        selectionLevel_0 === "motherInlaw" ||
        selectionLevel_0 === "brotherInlaw" ||
        selectionLevel_0 === "sisterInlaw") &&
      mode === "create"
    ) {
      let temp = relations.find(
        (item) =>
          item.relation.toLowerCase() === "wife" ||
          (item.relation.toLowerCase() === "husband" &&
            item.relation_source.toLowerCase() === "marriage")
      );
      if (!temp) {
        setCurrentPage("noInlawsPossible");
      } else {
        setCurrentPage("level_1");
      }
    }
  };
  const handleLevel_1 = () => {
    if (createNewRelations[selectionLevel_0]?.level === 2) {
      const isPaternal = relatedTo.relation.toLowerCase().includes("father");
      const matPatFlag = isPaternal ? "paternal" : "maternal";
      const filterRelationSides = relations.filter((item) => {
        if (
          selectionLevel_0 === "greatGrandFather" ||
          selectionLevel_0 === "greatGrandMother"
        )
          return (
            (item.relation.toLowerCase() === "grandfather" ||
              item.relation.toLowerCase() === "grandmother") &&
            item.matPatFlag.toLowerCase() === matPatFlag
          );
        return (
          (item.relation.toLowerCase() === "uncle" ||
            item.relation.toLowerCase() === "aunt") &&
          item.matPatFlag.toLowerCase() === matPatFlag
        );
      });
      if (filterRelationSides.length === 0) {
        if (
          selectionLevel_0 === "cousinSister" ||
          selectionLevel_0 === "cousinBrother"
        ) {
          setCurrentPage("noPossibleCousins");
        }
        if (
          selectionLevel_0 === "greatGrandFather" ||
          selectionLevel_0 === "greatGrandMother"
        ) {
          setCurrentPage("noPossibleGreatGrandParents");
        }
      } else {
        setCurrentPage("level_2");
        setRelationListLevel_2(filterRelationSides);
      }

      // setRelatedTo(self);
      // close("next");
    } else {
      let relation = relations.filter(
        (item) => item.userId === relatedTo?.userId
      );
      if (relation.length > 0) {
        if (
          (relation[0].rel_chain !== null &&
            relation[0].rel_chain?.includes("P")) ||
          relation[0].relation_source.toLowerCase().startsWith("step")
        ) {
          setCurrentPage("noRelationPossible");
          setStatus("invite");
        } else if (
          relation[0].relation.toLowerCase().endsWith("mother") ||
          relation[0].relation.toLowerCase().endsWith("father") //||
          // relation[0].relation.toLowerCase().includes("parent")
        ) {
          if (relationship === "Son" || relationship === "Daughter") {
            setCurrentPage("noDesendantPossible");
            setStatus("invite");
          } else {
            handleApi();
          }
          /*Allow 'Brother', 'Sister', 'Father', 'Mother', 'Wife','Husband'
			
			Show this message if they choose "Son" or "Daughter" - "If you are trying to create the Lifograf of an Uncle/Aunt or GrandUncle/GrandAunt, please create them as a Sibling of an Ancestor. For example, to create the Lifograf of your own Uncle, create it as a Brother of your Father, instead of creating it as a Son of one of your GrandParents.
			
			To avoid incorrect Lifografs / unauthorized ownership, creating Lifografs of other step / distant relatives is not allowed.
			
			Instead, please use the below links to invite them/their family members to create their own Lifografs & then connect with them"*/
        } else if (
          relation[0].relation == "Son" ||
          relation[0].relation == "Daughter" ||
          relation[0].relation == "Self"
        ) {
          handleApi();
        } else if (
          relation[0].rel_chain !== null &&
          relation[0].rel_chain.includes("S")
        ) {
          if (relationship === "Husband" || relationship === "Wife") {
            handleApi();
          } else {
            setCurrentPage("onlySpousePossible");
            setStatus("invite");
          }
          /*Allow only Spouses.
			Main Ques - " Only direct or first level relatives can be created""

			Sub Text - "You can create Lifografs of your ancestors, children and their respective spouses. You can also create Lifografs of your siblings & siblings of your ancestors.

			To avoid incorrect Lifografs / unauthorized ownership, creating Lifografs of other indirect / distant relatives is not allowed. Instead, please use the below links to invite them or their family members to create their own Lifograf & then connect with them." */
        } else {
          setCurrentPage("noRelationPossible");
          setStatus("invite");
        }
      } else {
        setCurrentPage("notARelation");
        setStatus("oopse");
      }
    }
  };
  const handleLevel_2 = () => {
    let relation = relations.filter(
      (item) => item.userId === relatedTo?.userId
    );
    if (relation.length > 0) {
      if (
        relation[0].relation.toLowerCase() === "uncle" ||
        relation[0].relation.toLowerCase() === "aunt" //||
        // relation[0].relation.toLowerCase().includes("parent")
      ) {
        if (relationship === "Son" || relationship === "Daughter") {
          setCurrentPage("noRelationPossible");
          setStatus("invite");
        } else {
          handleApi();
        }
      } else if (
        relation[0].relation.toLowerCase() === "grandfather" ||
        relation[0].relation.toLowerCase() === "grandmother"
      ) {
        if (relationship === "Father" || relationship === "Mother") {
          handleApi();
        } else {
          setCurrentPage("noCousinPossible");
          setStatus("invite");
        }
      }
    } else {
      setCurrentPage("noCousinPossible");
      setStatus("invite");
    }
  };
  const handleClose = () => {
    if (type === "spouse") {
      close();
    } else {
      setCurrentPage("level_0");
      setSelectionLevel_0();
      close();
    }
  };
  return (
    <div>
      {currentPage === "level_0" && (
        <CustomDialogSwipeUp
          open={open}
          close={handleClose}
          title={t("text.chooseRelationship")}
          iconPlacement="right"
        >
          <Stack spacing={2}>
            <Grid container ref={ref}>
              {Object.keys(createNewRelations).map((item, idx) => (
                <Grid item xs={4} md={3} key={idx} sx={{ p: 1 }}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                    className={
                      createNewRelations[item].gender === "male"
                        ? `male-button ${
                            selectionLevel_0 === item ? "selected" : ""
                          }`
                        : `female-button ${
                            selectionLevel_0 === item ? "selected" : ""
                          }`
                    }
                  >
                    <Button
                      startIcon={
                        <img
                          style={{ height: "50px", width: "50px" }}
                          src={relationshipIcons[item]}
                        />
                      }
                      sx={{
                        flexDirection: "column",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                        width: "100%",
                      }}
                      onClick={() => handleSelectionLevel_0(item)}
                      disableRipple={true}
                    >
                      <Typography
                        sx={{
                          display: "block",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: `${width / 4 - 15}px`,
                          whiteSpace: "wrap",
                          // color: "#333333",
                          px: 0.5,
                        }}
                      >
                        {t(`text.${item}`)}
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>
            {selectionLevel_0 && (
              <Card
                sx={{
                  width: "100%",
                  p: 1,
                  backgroundColor: "background.main",
                  // display: "flex",
                  borderLeft: "7px",
                  borderLeftStyle: "solid",
                  borderLeftColor: "info.main",
                  borderBottomLeftRadius: 0,
                  borderTopLeftRadius: 0,
                }}
              >
                <Typography
                  sx={{
                    flex: 1,
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "info.main",
                  }}
                >
                  {t(`text.${selectionLevel_0}`)}
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#666666",
                  }}
                >
                  {t(`text.desc${selectionLevel_0}`)}
                </Typography>
              </Card>
            )}
            <Divider></Divider>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <LoadingButton
                  sx={{ width: "30%" }}
                  variant="contained"
                  onClick={handleLevel_0}
                  disabled={!selectionLevel_0}
                  loading={loading}
                >
                  {t(`button.next`)}
                </LoadingButton>
              </Grid>
            </Grid>
          </Stack>
        </CustomDialogSwipeUp>
      )}
      {currentPage === "level_1" && (
        <CustomDialogSwipeUp
          open={open}
          close={handleClose}
          title={
            mode === "add"
              ? t("text.connectWith")
              : type === "spouse"
              ? t("text.addSpouse")
              : t("text.relationshipPopupHeader")
          }
          iconPlacement="right"
          sx={{ ".MuiPaper-root": { width: "100%" } }}
        >
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Card
              sx={{
                width: "100%",
                p: 1,
                backgroundColor: "background.main",
                display: "flex",
                borderLeft: "7px",
                borderLeftStyle: "solid",
                borderLeftColor: "info.main",
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
              }}
            >
              {description[selectionLevel_0]}
            </Card>
            {name && (
              <Typography
                sx={{ display: "flex", justifyContent: "center" }}
                variant="h6"
              >
                {t("text.is", { param1: name })}
              </Typography>
            )}
            <Box
              sx={{
                width: "100%",
                mt: 3,
              }}
            >
              {createNewRelations[selectionLevel_0]
                ?.relatedToList()
                ?.filter((item) =>
                  relationship === "Husband" || relationship === "Wife"
                    ? item.gender.toLowerCase() !==
                      chooseGenderFromValue(relationship)
                    : item
                ).length === 0 ? (
                <MenuItem
                  disabled
                  sx={{ maxheight: "240px", maxWidth: "240px" }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#333333",
                      textWrap: "wrap",
                    }}
                  >
                    {type === "spouse"
                      ? t("text.noSpouseFound", {
                          param1: name || "",
                        })
                      : t("text.noRelationFound", {
                          param1: t(`text.${selectionLevel_0}`),
                        })}
                  </Typography>
                </MenuItem>
              ) : (
                createNewRelations[selectionLevel_0]
                  ?.relatedToList()
                  ?.filter((item) =>
                    relationship === "Husband" || relationship === "Wife"
                      ? item.gender.toLowerCase() !==
                        chooseGenderFromValue(relationship)
                      : item
                  )
                  .map((option, index) => {
                    return (
                      <Box
                        sx={{ width: "100%", display: "flex" }}
                        onClick={() => setRelatedTo(option)}
                        key={index}
                      >
                        <CustomNameTag
                          src={option.readURL}
                          name={option.name}
                          selected={relatedTo?.userId === option.userId}
                        />
                      </Box>
                    );
                  })
              )}
            </Box>
            <Divider></Divider>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <LoadingButton
                  disabled={
                    relatedTo === null
                      ? true
                      : relationship === null
                      ? true
                      : false
                  }
                  sx={{ width: "30%" }}
                  variant="contained"
                  onClick={() => handleLevel_1()}
                  loading={loading}
                >
                  {mode === "create"
                    ? t(`button.create`)
                    : t(`button.add${type}`)}
                </LoadingButton>
              </Grid>
            </Grid>
          </Stack>
        </CustomDialogSwipeUp>
      )}
      {currentPage === "level_2" && (
        <CustomDialogSwipeUp
          open={open}
          close={handleClose}
          title={
            mode === "add"
              ? t("text.connectWith")
              : type === "spouse"
              ? t("text.addSpouse")
              : t("text.relationshipPopupHeader")
          }
          iconPlacement="right"
          sx={{ ".MuiPaper-root": { width: "100%" } }}
        >
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Card
              sx={{
                width: "100%",
                p: 1,
                backgroundColor: "background.main",
                display: "flex",
                borderLeft: "7px",
                borderLeftStyle: "solid",
                borderLeftColor: "info.main",
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
              }}
            >
              {description[selectionLevel_0]}
            </Card>
            {name && (
              <Typography
                sx={{ display: "flex", justifyContent: "center" }}
                variant="h6"
              >
                {t("text.is", { param1: name })}
              </Typography>
            )}
            <Box
              sx={{
                width: "100%",
                mt: 3,
              }}
            >
              {relationListLevel_2.length === 0 ? (
                <MenuItem
                  disabled
                  sx={{ maxheight: "240px", maxWidth: "240px" }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#333333",
                      textWrap: "wrap",
                    }}
                  >
                    {type === "spouse"
                      ? t("text.noSpouseFound", {
                          param1: name || "",
                        })
                      : t("text.noRelationFound", {
                          param1: t(`text.${selectionLevel_0}`),
                        })}
                  </Typography>
                </MenuItem>
              ) : (
                relationListLevel_2.map((option, index) => {
                  return (
                    <Box
                      sx={{ width: "100%", display: "flex" }}
                      onClick={() => {
                        setRelatedTo(option);
                        setSelectionLevel_2(option);
                      }}
                      key={index}
                    >
                      <CustomNameTag
                        src={option.readURL}
                        name={option.name}
                        selected={relatedTo.userId === option.userId}
                      />
                    </Box>
                  );
                })
              )}
            </Box>
            <Divider></Divider>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <LoadingButton
                  disabled={!selectionLevel_2}
                  sx={{ width: "30%" }}
                  variant="contained"
                  onClick={() => handleLevel_2()}
                  loading={loading}
                >
                  {mode === "create"
                    ? t(`button.create`)
                    : t(`button.add${type}`)}
                </LoadingButton>
              </Grid>
            </Grid>
          </Stack>
        </CustomDialogSwipeUp>
      )}
      {currentPage === "noRelationPossible" && (
        <DeleteEntryPromt
          buttonType="single"
          open={open}
          singleButtonOnClick={handleClose}
          close={handleClose}
          type="info"
          title={t("button.invite")}
          message={
            <Box>
              <Typography
                variant="body1"
                sx={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
              >
                {t("text.cannotCreateRelationInvitePromt")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  mt: 1,
                }}
              >
                <InvitePeople
                  relation={relationship}
                  lg_id={relatedTo?.userId}
                />
              </Box>
            </Box>
          }
        />
      )}
      {(currentPage === "noPossibleGrandChildren" ||
        currentPage === "noPossibleGrandParents" ||
        currentPage === "noPossibleCousins" ||
        currentPage === "noPossibleGreatGrandParents" ||
        currentPage === "noPossibleStepSiblings" ||
        currentPage === "noPossibleUncleAunt") && (
        <DeleteEntryPromt
          buttonType="single"
          open={open}
          singleButtonOnClick={handleClose}
          close={handleClose}
          type="info"
          title={t("text.relationshiWarning")}
          message={
            <Box>
              <Typography
                variant="body1"
                sx={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
              >
                {t(`text.${currentPage}`)}
              </Typography>
            </Box>
          }
        />
      )}
      {currentPage === "noDesendantPossible" && (
        <DeleteEntryPromt
          buttonType="single"
          open={open}
          singleButtonOnClick={handleClose}
          close={handleClose}
          type="info"
          title={t("button.invite")}
          message={
            <Box>
              <Typography
                variant="body1"
                sx={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
              >
                {t("text.noDesendantPossible")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  mt: 1,
                }}
              >
                <InvitePeople
                  relation={relationship}
                  lg_id={relatedTo?.userId}
                />
              </Box>
            </Box>
          }
        />
      )}
      {currentPage === "onlySpousePossible" && (
        <DeleteEntryPromt
          buttonType="single"
          open={open}
          singleButtonOnClick={handleClose}
          close={handleClose}
          type="info"
          title={t("button.invite")}
          message={
            <Box>
              <Typography
                variant="body1"
                sx={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
              >
                {t("text.onlySpousePossible")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  mt: 1,
                }}
              >
                <InvitePeople
                  relation={relationship}
                  lg_id={relatedTo?.userId}
                />
              </Box>
            </Box>
          }
        />
      )}
      {currentPage === "uniqueRelation" && (
        <DeleteEntryPromt
          buttonType="single"
          open={open}
          singleButtonOnClick={handleClose}
          close={handleClose}
          type="info"
          title={t("text.alreadyThere")}
          message={
            <Box>
              {" "}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CustomAvatar src={relatedTo?.readURL} sx={{ mr: 1 }} />
                <Typography>
                  {t("text.alreadyHasLifograf", {
                    param1: `${relatedTo?.fname} ${relatedTo?.lname}`,
                    param2: `${
                      relatedTo?.gender === "Male"
                        ? t("text.his")
                        : t("text.her")
                    }`,
                    param3: relationship,
                  })}{" "}
                  {t("text.youCanVisitLifografByClicking", {
                    param1: `${relatedTo?.fname} ${relatedTo?.lname}`,
                    param2: relationship,
                  })}
                </Typography>
              </Box>
              <Typography sx={{ mt: 2 }} align="center"></Typography>
              <center>
                <Button
                  variant="contained"
                  sx={{ mt: 2, justifyContent: "flex-start" }}
                  startIcon={
                    <CustomAvatar
                      sizes="small"
                      src={uniqueRelation[0].readURL}
                    />
                  }
                  onClick={() => {
                    navigate(
                      `/profile/${uniqueRelation[0].link.split("/")[4]}/about`
                    );
                    close();
                  }}
                >
                  {` ${uniqueRelation[0].name}`}
                </Button>
              </center>
            </Box>
          }
        />
      )}
      {currentPage === "relationExist" && (
        <DeleteEntryPromt
          open={open}
          onCancel={handleClose}
          onDelete={() => close("next")}
          // close={handleClose}
          type="info"
          title={t("text.alreadyThere")}
          message={
            <Box>
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CustomAvatar src={relatedTo?.readURL} sx={{ mr: 1 }} />
                <Typography>
                  {t("text.alreadyhas", {
                    param1: `${relatedTo?.name}`,
                    param2: relationExist.length,
                    param3: relationship,
                    param4: relationExist.length > 1 ? t("text.s") : "",
                  })}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "inline-grid",
                  maxHeight: "250px",
                  overflowY: "scroll",
                }}
                className={classes.root}
              >
                <ul>
                  {relationExist.map((item) => (
                    <li>
                      <Button
                        color={"black"}
                        variant="text"
                        sx={{ justifyContent: "flex-start" }}
                        startIcon={
                          <CustomAvatar sizes="small" src={item.readURL} />
                        }
                        onClick={() => {
                          navigate(`/profile/${item.link.split("/")[4]}/about`);
                          close();
                        }}
                      >
                        {` ${item.name}`}
                      </Button>
                    </li>
                  ))}
                </ul>
              </Box>
              <Typography variant="body1">
                {t("text.notCreatingDuplicate", {
                  param1: relationship,
                })}
              </Typography>
            </Box>
          }
        />
      )}
      {currentPage === "notARelation" && (
        <DeleteEntryPromt
          buttonType="single"
          open={open}
          singleButtonOnClick={handleClose}
          close={handleClose}
          type="info"
          title={t("button.invite")}
          message={t("granny.notARelation")}
        />
      )}
      {currentPage === "nieceNephew" && (
        <DeleteEntryPromt
          buttonType="single"
          open={open}
          singleButtonOnClick={handleClose}
          close={handleClose}
          type="info"
          title={t(`text.${selectionLevel_0}`)}
          message={description[selectionLevel_0]}
        />
      )}
      {currentPage === "noCousinPossible" && (
        <DeleteEntryPromt
          buttonType="single"
          open={open}
          singleButtonOnClick={handleClose}
          close={handleClose}
          type="info"
          title={t(`text.${selectionLevel_0}`)}
          message={description[selectionLevel_0]}
        />
      )}
      {currentPage === "noInlawsPossible" && (
        <DeleteEntryPromt
          buttonType="single"
          open={open}
          singleButtonOnClick={handleClose}
          close={handleClose}
          type="info"
          title={t(`text.${selectionLevel_0}`)}
          message={description[selectionLevel_0]}
        />
      )}
    </div>
  );
};
