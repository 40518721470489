import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import { t } from "i18next";
import { useState } from "react";
import { DeleteEntryPromt } from "../atoms/DeleteEntryPromt";
import { useAddRelationMutation } from "../../redux/slice/addRelation";
import { chooseGenderFromValue } from "../../utils/functions";
import { useClaimLgMutation } from "../../redux/slice/claimLg";

export const DuplicateLg = ({
  data,
  lg_id,
  relationship,
  onNext = () => {},
  mode,
}) => {
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [addRelation, { isLoading }] = useAddRelationMutation();
  const [claimLg] = useClaimLgMutation();
  const relationshipGender = chooseGenderFromValue(relationship);
  let searchList = data?.searchList || [];

  const handleConfirm = () => {
    if (mode === "self") {
      claimLg({ claimedLgId: selectedUser.userId })
        .then(() => {
          onNext();
        })
        .catch((e) => console.log(e));
    } else {
      addRelation({
        lg_id: lg_id,
        base_lg_id: selectedUser.userId,
        base_relation: relationship,
      })
        .then(() => {
          onNext();
        })
        .catch((e) => console.log(e));
    }
  };
  const handleClose = () => {
    setSelectedUser(undefined);
  };
  return (
    <>
      <Card sx={{ width: "100%", p: 1 }}>
        <Stack spacing={1}>
          {searchList
            .filter((item) => {
              if (relationship === "self") {
                return true;
              }
              return item.gender.toLowerCase() === relationshipGender;
            })
            .map((item) => (
              <>
                <Button
                  sx={{
                    width: "100%",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    textAlign: "justify",
                  }}
                  startIcon={<Avatar src={item.readURL} />}
                  onClick={() => setSelectedUser(item)}
                >
                  <Stack spacing={1}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#333333",
                        fontWeight: 600,
                      }}
                    >
                      {item.name}
                    </Typography>
                    {item.parentNames && (
                      <Typography sx={{ fontSize: "14px", color: "#666666" }}>
                        {t("text.parentsName", { param1: item.parentNames })}
                      </Typography>
                    )}
                    {item.childNames && (
                      <Typography sx={{ fontSize: "14px", color: "#666666" }}>
                        {t("text.childNames", { param1: item.childNames })}
                      </Typography>
                    )}
                    {item.siblingNames && (
                      <Typography sx={{ fontSize: "14px", color: "#666666" }}>
                        {t("text.siblingNames", { param1: item.siblingNames })}
                      </Typography>
                    )}
                  </Stack>
                </Button>
                <Divider></Divider>
              </>
            ))}
        </Stack>
      </Card>
      <DeleteEntryPromt
        open={Boolean(selectedUser)}
        close={handleClose}
        onCancel={handleClose}
        onDelete={handleConfirm}
        title={t("text.areYouSure")}
        message={
          <Typography
            sx={{
              wordWrap: "break-word",
              whiteSpace: "pre-wrap",
            }}
          >
            {t(mode === "self" ? "text.claimLg" : "text.confirmEmailPromt")}
          </Typography>
        }
        type={"success"}
      />
    </>
  );
};
