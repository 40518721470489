import React, { useEffect } from "react";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { t } from "i18next";
import { RelationRequestSkleton } from "../Skleton/RelationRequestSkleton";
import { useLazyGetHomeFeedQuery } from "../../redux/slice/homeFeed";
import { NotificationPill } from "../atoms/NotificationPill";
import { useSelector } from "react-redux";

export const Notification = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [trigger, { data, isLoading, isFetching }] = useLazyGetHomeFeedQuery();
  const { device } = useSelector((state) => state.config);

  useEffect(() => {
    trigger();
  }, []);

  //-----------------------Declare Functions Here-----------------------------//

  return (
    <Card sx={{ py: device !== "sm" ? 1.5 : 0 }}>
      <Stack spacing={1}>
        <Stack spacing={1} sx={{ width: "100%" }}>
          {device !== "sm" && (
            <Typography
              align="center"
              sx={{ fontSize: "20px", fontWeight: 400, color: "#333333" }}
            >
              {t("text.notifications")}
            </Typography>
          )}
          {device !== "sm" && <Divider></Divider>}
        </Stack>
        {isLoading || isFetching ? (
          <RelationRequestSkleton />
        ) : (
          <Card
            sx={{
              p: 1,
              mt: device !== "sm" ? 1 : 0,
              minHeight: "76vh",
              width: "100%",
            }}
          >
            {data ? (
              <Stack spacing={1}>
                {data.alertList
                  .filter((item) => item.notif_catg !== "tip")
                  .map((item) => (
                    <NotificationPill
                      data={item}
                      sx={{
                        borderStyle: "solid",
                        borderColor: "border.main",
                        borderWidth: "thin",
                        backgroundColor: "#fff",
                      }}
                    />
                  ))}
              </Stack>
            ) : (
              <RelationRequestSkleton />
            )}
          </Card>
        )}
      </Stack>
    </Card>
  );
};
