import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { UserNameCard } from "../atoms/UserNameCard";
import {
  findOwnerRelationShipStatus,
  formatRelationship,
  sortRelation,
} from "../../utils/functions";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { t } from "i18next";
import SearchIcon from "@mui/icons-material/Search";
import { motion, AnimatePresence } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import { ownerIcons } from "../../utils/ListItems";
import { HelperIcon } from "../atoms/HelperIcon";

export const Relations = ({ toggle = () => {} }) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const { relations } = useSelector((state) => state.userSummary);
  const [showSearch, setShowSearch] = useState(false);
  const [sortedRelation, setSortedRelation] = useState(relations);
  const textField = useRef();
  const navigate = useNavigate();
  const hasRelation =
    relations?.length > 0
      ? relations.filter((item) => item.relation.toLowerCase() !== "self")
          .length > 0
      : false;
  //-----------------------Declare Function Here-----------------------------//
  const searchRelation = (searchData) => {
    if (searchData.length > 0) {
      let value = searchData.toLowerCase();
      let data = relations.filter((item) => {
        return (
          item.name.toLowerCase().startsWith(value) ||
          item.relation.toLowerCase().includes(value)
        );
      });
      setSortedRelation(data);
    } else setSortedRelation(relations);
  };
  const toggleSearch = () => {
    setShowSearch(!showSearch);
    setSortedRelation(relations);
  };

  useEffect(() => {
    setSortedRelation(relations);
  }, [relations]);

  const RenderRelation = ({ type, data = [] }) => {
    return (
      <>
        <Stack
          direction={"row"}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              pt: 0.5,
              color: "#3F0D56",
              fontSize: "14px",
              fontWeight: 800,
            }}
          >
            {t(`text.${type}`)}
          </Typography>
          <HelperIcon
            helperText={
              <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
                {t(`helperText.relationshipCategory.${type}`)}
              </Typography>
            }
          />
        </Stack>
        <AnimatePresence mode="wait">
          {data.length > 0 ? (
            data.toSorted(sortRelation).map((item, idx) => (
              // <motion.div
              //   layout
              //   initial={{ opacity: 0 }}
              //   animate={{ opacity: 1 }}
              //   exit={{ opacity: 0, transition: { delay: 0, duration: 0.1 } }}
              //   transition={{
              //     type: "spring",
              //     duration: 1.5,
              //     delay: idx / 10,
              //   }}
              //   key={idx}
              // >
              <MenuItem
                key={idx}
                onClick={() => {
                  navigate(`/profile/${item.link.split("/")[4]}/about`);
                  toggle();
                }}
              >
                <UserNameCard
                  src={item.readURL}
                  name={item.name}
                  relation={formatRelationship(item.userId)}
                  ownerType={
                    findOwnerRelationShipStatus(item.userId)?.owner_type
                  }
                />
              </MenuItem>
              // </motion.div>
            ))
          ) : (
            <Stack spacing={1} sx={{ p: 2, width: "100%" }}>
              <Typography
                sx={{ fontSize: "12px", color: "#666666", fontWeight: 300 }}
                align="center"
              >
                {t("text.noRelation")}
              </Typography>
              <Typography
                sx={{ fontSize: "12px", color: "#666666", fontWeight: 300 }}
                align="center"
              >
                {t("text.useCreateOrInvite")}
              </Typography>
            </Stack>
          )}
        </AnimatePresence>
      </>
    );
  };

  let immediate = sortedRelation.filter((item) => item.rel2_bin === "imm");
  let extended = sortedRelation.filter((item) => item.rel2_bin === "ext");
  let firstCousins = sortedRelation.filter((item) => item.rel2_bin === "1c");
  let distant = sortedRelation.filter((item) => item.rel2_bin === "dist");

  return (
    <Stack spacing={1}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          px: 1,
          pt: 1,
        }}
      >
        <AnimatePresence mode={"wait"}>
          {showSearch && (
            <motion.div
              key="textbox"
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: 100, opacity: 0 }}
              style={{ width: "100%" }}
              transition={{
                duration: 0.1,
              }}
            >
              <TextField
                sx={{
                  p: 0,
                  height: "24px",
                  ".MuiInputBase-root": { height: "24px" },
                  width: "100%",
                }}
                onChange={(e) => searchRelation(e.target.value)}
                ref={textField}
              />
            </motion.div>
          )}
          {!showSearch && (
            <motion.div
              key="typography"
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -40, opacity: 0 }}
              transition={{
                duration: 0.1,
              }}
            >
              <Typography
                sx={{ color: "#3F0D56", fontSize: "18px", fontWeight: 800 }}
              >
                {t("text.relations")}
              </Typography>
            </motion.div>
          )}
        </AnimatePresence>
        <IconButton sx={{ p: 0, ml: 1 }} onClick={toggleSearch}>
          {showSearch ? <CloseIcon /> : <SearchIcon />}
        </IconButton>
      </Box>
      <Divider></Divider>
      {hasRelation && (
        <>
          <Stack spacing={1}>
            {Object.keys(ownerIcons).map((item, idx) => (
              <Stack
                spacing={1}
                direction={"row"}
                key={idx}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  px: 1,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    style={{ marginLeft: "4px", height: "12px", width: "12px" }}
                    src={ownerIcons[item]}
                  />
                  <Typography sx={{ fontSize: "12px", color: "#666666" }}>
                    {`- ${t(`toolTip.${item}`)}`}
                  </Typography>
                </Box>
                <HelperIcon
                  helperText={
                    <Typography
                      sx={{
                        wordWrap: "break-word",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {t(`helperText.${item.toLowerCase()}`)}
                    </Typography>
                  }
                />
              </Stack>
            ))}
          </Stack>
          <Divider></Divider>
        </>
      )}
      <Box sx={{ px: 1 }}>
        <RenderRelation type={"immediate"} data={immediate} />
        <Divider></Divider>
        <RenderRelation type={"extended"} data={extended} />
        <Divider></Divider>
        <RenderRelation type={"firstCousins"} data={firstCousins} />
        <Divider></Divider>
        <RenderRelation type={"distant"} data={distant} />
      </Box>
    </Stack>
  );
};
